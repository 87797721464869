import React, { useState, useEffect } from "react";
import { Ai } from "@/interfaces/ai";
import { Meta } from "@/interfaces/meta";
import { MainCategory } from "@/interfaces/main_category";
import AdminsAiCloneLink from "@/components/pages/vendors/ais/CloneLink";
import { searchAi } from "@/libs/api/vendors/ai";
import { toast } from "react-toastify";
import Pagination from "@/components/partials/navs/Pagination";

type Props = {
  mainCategories: MainCategory[];
  selectMainCategoryId?: string;
  selectCategoryPath: string;
};

const VendorsIndex: React.FC<Props> = (props) => {
  const [searchAis, setSearchAis] = useState<Ai[]>([]);
  const [searchMeta, setSearchMeta] = useState<Meta | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [debouncedText, setDebouncedText] = useState<string>(searchText);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await searchAi(searchText, props.selectMainCategoryId);
        setSearchAis(response.data.ais);
        setSearchMeta(response.data.meta);
      } catch (error) {
        toast.error("データの取得に失敗しました");
      }
    };

    fetchInitialData();
  }, [props.selectMainCategoryId]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedText(searchText);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  useEffect(() => {
    const search = async () => {
      try {
        const response = await searchAi(debouncedText, props.selectMainCategoryId);
        setSearchAis(response.data.ais);
        setSearchMeta(response.data.meta);
        updateURLParams('page', response.data.meta.currentPage);
      } catch (error) {
        toast.error('検索に失敗しました');
      }
    };

    search();
  }, [debouncedText]);

  const movePage = async (page: number) => {
    try {
      const response = await searchAi(searchText, props.selectMainCategoryId, page);
      setSearchAis(response.data.ais);
      setSearchMeta(response.data.meta);
      updateURLParams('page', response.data.meta.currentPage);
    } catch (error) {
      toast.error('検索に失敗しました');
    }
  };

  const updateURLParams = (key: string, value: string) => {
    const url = new URL(window.location.href);
  
    url.searchParams.set(key, value);
  
    window.history.replaceState({}, '', url.toString());
  };

  const textChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const generateHref = (category: MainCategory | undefined): string => {
    return category ? `${props.selectCategoryPath}?main_category_id=${category.id}` : props.selectCategoryPath;
  };

  const deleteConfirm = (event: React.MouseEvent<HTMLElement>) => {
    const result = window.confirm('削除しますか？');
    if (!result) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <>
      <h2 className="text-md font-semibold text-gray-900 mt-3">カテゴリを絞り込む</h2>
      <div className="flex flex-wrap">
      {props.mainCategories.map((category) => (
        <a
          key={`main-category-${category.slug}`}
          className={`rounded-md py-2 px-5 text-sm font-semibold flex items-center flex-wrap mr-3 mt-2 ${
            category.id == props.selectMainCategoryId
              ? 'bg-blue-700 text-white hover:bg-blue-60 shadow-sm'
              : 'bg-white text-gray-600 hover:bg-gray-50 ring-1 ring-inset ring-gray-300'
          }`}
          href={category.id == props.selectMainCategoryId ? generateHref(undefined) : generateHref(category)}
        >
          <span className="mr-2">
            <i className={`${category.fontawesomeClass}`}></i>
          </span>
          <span>{category.name}</span>
        </a>
      ))}
      </div>
      <div className="mt-5 relative inline-block text-left w-full">
        <label
          htmlFor="search"
          className="block text-md font-semibold text-gray-900"
        >
          生成ツールをキーワードで検索
        </label>
        <div className="relative mt-2 flex items-center">
          <input
            type="text"
            onChange={textChanged}
            className="border border-gray-300 rounded-md p-2 w-full"
            placeholder="例)ブログ"
            name="search"
          />
        </div>
      </div>
      <div className="relative overflow-x-auto mt-5">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th className="px-3 py-3" scope="col">画像</th>
              <th className="px-3 py-3" scope="col">名前</th>
              <th className="px-3 py-3" scope="col">大カテゴリー</th>
              <th className="px-3 py-3" scope="col">中カテゴリー</th>
              <th className="px-3 py-3" scope="col">小カテゴリー</th>
              <th className="px-3 py-3" scope="col">並び順</th>
              <th className="px-3 py-3" scope="col">公開ステータス</th>
              <th className="px-3 py-3" scope="col">操作</th>
            </tr>
          </thead>
          <tbody>
            {searchAis.map((ai) => {
              return (
                <tr key={`ai-${ai.id}-${ai.slug}`} className="bg-white border-b">
                  <th className="px-3 py-4 w-24">
                    <img className="w-full max-w-xs flex-shrink-0 rounded-full bg-gray-300" src={ai.image.thumb.url}></img>
                  </th>
                  <th className="px-3 py-4">{ai.name}</th>
                  <td className="px-3 py-4">{ai.mainCategoryName}</td>
                  <td className="px-3 py-4">{ai.subCategoryName}</td>
                  <td className="px-3 py-4">{ai.leafCategoryName}</td>
                  <td className="px-3 py-4">{ai.orderIdx}</td>
                  <td className="px-3 py-4">{ai.status}</td>
                  <td className="px-3 py-4">
                    <div>
                      <a
                        className="inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900" 
                        href={ai.editVendorsAiPath}
                      >
                        編集
                      </a>
                      <AdminsAiCloneLink slug={ai.slug}></AdminsAiCloneLink>
                    </div>
                    <div>
                      <a
                        className="inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900" 
                        onClick={deleteConfirm} rel="nofollow" data-method="delete" href={ai.vendorsAiPath}
                      >
                        削除
                      </a>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {searchMeta && (
        <Pagination meta={searchMeta} selectPage={movePage} />
      )}
    </>
  );
};

export default VendorsIndex;
