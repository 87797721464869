import React, { useState, useEffect } from "react";
import { Meta } from "@/interfaces/meta";

type Props = {
  meta: Meta;
  selectPage: (page: number) => void;
  windowCount?: number;
  left?: number;
  right?: number;
};

const Pagination: React.FC<Props> = ({
  meta,
  selectPage,
  windowCount = 2,
  left = 2,
  right = 2
}) => {
  const [existNext, setExistNext] = useState(meta.nextPage !== null);
  const [existPrev, setExistPrev] = useState(meta.prevPage !== null);

  useEffect(() => {
    setExistNext(meta.nextPage !== null);
    setExistPrev(meta.prevPage !== null);
  }, [meta]);

  const generatePageNumbers = () => {
    const pageNumbers: (number | string)[] = [];
    const { currentPage, totalPages } = meta;
  
    for (let i = 1; i <= Math.min(left, totalPages); i++) {
      if (!pageNumbers.includes(i)) pageNumbers.push(i);
    }
  
    if (currentPage > left + windowCount) {
      pageNumbers.push('...');
    }
  
    const start = Math.max(currentPage - windowCount, left + 1);
    const end = Math.min(currentPage + windowCount, totalPages - right);
    for (let i = start; i <= end; i++) {
      if (!pageNumbers.includes(i)) pageNumbers.push(i);
    }
  
    if (currentPage + windowCount < totalPages - right) {
      pageNumbers.push('...');
    }
  
    for (let i = Math.max(totalPages - right + 1, 1); i <= totalPages; i++) {
      if (!pageNumbers.includes(i)) pageNumbers.push(i);
    }
  
    return pageNumbers;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <nav className="pagination mt-10">
      <ul className="inline-flex -space-x-px">
        {
          existPrev &&
            <li className="first">
              <button
                onClick={() => selectPage(1)}
                className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
              >
                <span className="translation_missing" title="translation missing: ja.views.pagination.first">
                  First
                </span>
              </button>
            </li>
        }
        {
          existPrev &&
            <li className="prev">
              <button
                onClick={() => selectPage((meta.currentPage - 1))}
                className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
              >
                <span className="translation_missing" title="translation missing: ja.views.pagination.previous">
                  Previous
                </span>
              </button>
            </li>
        }
        {pageNumbers.map((page, index) =>
          page === '...' ? (
            <li key={`ellipsis-${page}-${index}`} className="page">
              <p className="px-3 py-2 ml-0 leading-tight text-gray-300 bg-white border border-gray-300">
                ...
              </p>
            </li>
          ) : page === meta.currentPage ? (
            <li key={`page-current-${page}`} className="page current">
              <p className="px-3 py-2 ml-0 leading-tight text-gray-300 bg-white border border-gray-300">
                {page}
              </p>
            </li>
          ) : (
            <li key={`page-${page}`} className="page">
              <button
                onClick={() => selectPage(page as number)}
                className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
              >
                {page}
              </button>
            </li>
          )
        )}
        {
          existNext && 
            <li className="next">
              <button
                onClick={() => selectPage((meta.currentPage + 1))}
                className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
              >
                <span className="translation_missing" title="translation missing: ja.views.pagination.next">
                  Next
                </span>
              </button>
            </li>
        }
        {
          existNext && 
            <li className="last">
              <button
                onClick={() => selectPage(meta.totalPages)}
                className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
              >
                <span className="translation_missing" title="translation missing: ja.views.pagination.last">
                  Last
                </span>
              </button>
            </li>
        }
      </ul>
    </nav>
  );
};

export default Pagination;
