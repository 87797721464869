import React from 'react';

interface IconWithTitleProps {
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    title: string;
    url: string;
}

const IconWithTitle: React.FC<IconWithTitleProps> = ({icon, title, url}) => {
    const IconComponent = icon;
    return (
        <div className="flex font-medium rounded-lg hover:bg-gray-100 hover:text-cwaipurple-600">
            <a href={url} className="p-1 w-full">
                <div className="flex items-center space-x-4">
                    <IconComponent className="w-6 h-6" />
                    <span className="text-md font-semibold">{title}</span>
                </div>
            </a>
        </div>
    );
};

export default IconWithTitle;
