import React, { useState } from "react";

type Props = {
  text: String;
};
const UserWhisperAiTranscribedText: React.FC<Props> = (props) => {
  const [showAll, setShowAll] = useState(false);
  return (
    <>
      <label
        htmlFor="street-address"
        className="block text-sm font-semibold leading-6 text-gray-900"
      >
        文字起こしされたテキスト
      </label>
      {showAll ? (
        <>
          <div className="relative">
            <span className="px-4 py-2 rounded-lg w-full inline-block border bg-[#f7f9f9] text-gray-800 whitespace-pre-wrap break-words text-sm">
              {props.text}
            </span>
          </div>
          <div className="text-center w-full">
            <button
              onClick={() => setShowAll(false)}
              className="text-cwaipurple-600 text-sm"
            >
              サマリを表示
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="relative">
            <span className="px-4 py-2 rounded-lg w-full inline-block border bg-[#f7f9f9] text-gray-800 whitespace-pre-wrap break-words text-sm max-h-40 overflow-hidden">
              {props.text}
            </span>
            <div
              className="absolute bottom-0 left-0 w-full text-center bg-white h-20"
              style={{
                background: "linear-gradient(to top, white, transparent)",
              }}
            ></div>
          </div>
          <div className="text-center w-full">
            <button
              onClick={() => setShowAll(true)}
              className="text-cwaipurple-600 text-sm"
            >
              全文を表示
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default UserWhisperAiTranscribedText;
