import React, { Fragment, useRef, useState } from "react";
import ReactLoading from "react-loading";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { updateSelfUser } from "../../../libs/api/user";

type Props = {
  open: boolean;
};

const AcceptPrivacyPolicy: React.FC<Props> = (props) => {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(props.open);

  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    setLoading(true);
    try {
      const now = new Date();
      const params = {
        policyAcceptAt: now.toISOString(),
      };
      await updateSelfUser(params);
      toast.success("プライバシーポリシーに同意しました");
      setOpen(false);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        // サーバーからのエラーレスポンス
        console.error("Error status:", error.response.status);
        console.error("Error message:", error.response.data.errors);
        toast.error(error.response.data.errors.join(","));
      } else {
        // その他のエラー（axiosの設定など）
        console.error("Error:", error.message);
        toast.error("エラーが発生しました");
      }
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      policyAccepted: false,
    },
  });

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={() => setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-4">
                  <div>
                    <div className="text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        プライバシーポリシーと利用規約が変更されました
                      </Dialog.Title>
                    </div>
                  </div>
                  {loading ? (
                    <>
                      <div className="flex justify-center">
                        <ReactLoading color={`#009dff`} />
                      </div>
                    </>
                  ) : (
                    <>
                      <form
                        className="simple_form edit_user mt-4"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div>
                          <div className="mb-4 boolean required ai_policy_accepted">
                            <div className="flex items-start justify-center">
                              <div className="flex h-5 items-center">
                                <input
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  {...register("policyAccepted", {
                                    required: true,
                                  })}
                                />
                              </div>
                              <div className="ml-3 text-sm text-center">
                                <label className="font-medium text-gray-700">
                                  <a
                                    href="https://a-i-tech.co/privacy_policy"
                                    className="text-blue-500"
                                    target="_blank"
                                  >
                                    プライバシーポリシー
                                  </a>
                                  と
                                  <a
                                    href="/contract"
                                    className="text-blue-500"
                                    target="_blank"
                                  >
                                    利用規約
                                  </a>
                                  に同意する
                                </label>
                              </div>
                            </div>
                            {errors.policyAccepted?.type === "required" && (
                              <p className="font-semibold text-sm text-red-500 text-center">
                                同意が必要です
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-6 flex justify-center">
                          <button
                            type="submit"
                            className="inline-flex w-full md:w-3/4 justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            送信
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default AcceptPrivacyPolicy;
