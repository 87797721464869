import React, { useState } from "react";
import { AiForm } from "../../../../../interfaces/ai_form";
import InputText from "../forms/InputText";
import TextArea from "../forms/TextArea";
import InputSelect from "../forms/InputSelect";
import { useForm } from "react-hook-form";
import { User } from "../../../../../interfaces/user";
import { FormNameAndValue } from "../../../../../interfaces/users_ai";
import InputFile from "../../../../users/ais/forms/InputFile";
import { UploadedFile } from "../../../../users/ais/forms/InputFile";

type Props = {
  submit: (data: any, uploadedFiles: UploadedFile[], privateFlag: boolean) => void;
  aiForms: AiForm[];
  streaming: boolean;
  cancelStreaming: () => void;
  user?: User;
  isAdmin?: boolean;
  formNameAndValues?: FormNameAndValue[];
};

const UserAiChatPromptSettingForm: React.FC<Props> = (props) => {
  const [privateFlag, setPrivateFlag] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  const setUploadedFile = (file: UploadedFile) => {
    const newUploadedFiles = uploadedFiles.filter((uploadedFile) => uploadedFile.inputName !== file.inputName);
    newUploadedFiles.push(file);

    setUploadedFiles(newUploadedFiles);
  }

  const {
    register,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useForm();

  // 特定のキーに対応する値を検索する関数
  const findValueByKey = (key: string): string | undefined => {
    if (props.formNameAndValues) {
      const item = props.formNameAndValues.find((item) => item.name === key);
      return item?.value;
    }
  };

  const inputTag = (aiForm, idx) => {
    let defaultValue = findValueByKey(aiForm.inputName);
    if (aiForm.kind == "input_text") {
      return (
        <InputText
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
          setValue={setValue}
          defaultValue={defaultValue}
        />
      );
    } else if (aiForm.kind == "text_area") {
      return (
        <TextArea
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
          setValue={setValue}
          defaultValue={defaultValue}
        />
      );
    } else if (aiForm.kind == "input_select") {
      return (
        <InputSelect
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
          setValue={setValue}
          defaultValue={defaultValue}
        />
      );
    } else if (aiForm.kind == "input_radio") {
    } else if (aiForm.kind == "input_file") {
      return (
        <InputFile
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
          setUploadedFile={setUploadedFile}
        />
      );
    }
  };

  const onSubmit = async () => {
    // 手動でバリデーションを行う
    const result = await trigger();

    if (result) {
      const data = getValues();

      props.submit(data, uploadedFiles, privateFlag);
    }
  };

  const submitBtn = () => {
    if (props.streaming == true) {
      return (
        <button
          className="w-full rounded bg-cwaipurple-600 py-3 mb-4 text-md font-semibold text-white shadow-sm hover:opacity-90"
          onClick={props.cancelStreaming}
        >
          生成キャンセル
        </button>
      );
    } else {
      return (
        <button
          type="button"
          onClick={onSubmit}
          className="w-full rounded bg-cwaipurple-600 py-2 mb-4 text-md font-semibold text-white shadow-sm hover:opacity-90"
        >
          生成する
        </button>
      );
    }
  };

  return (
    <>
      <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
        <div className="px-2">
          <div className="grid lg:grid-cols-12 md:grid-cols-12 gap-3">
            {props.aiForms.map((aiForm, idx) => {
              return inputTag(aiForm, idx);
            })}
          </div>
        </div>
        <div className="px-2 py-2">
          <input type="checkbox" name="private" id="private" value="1" className="mr-1" onChange={(event) => {
            setPrivateFlag(event.target.checked);
          }} />
          <label htmlFor="private">生成結果を非公開にする</label>
        </div>
        <div className="px-4 py-3 text-right">{submitBtn()}</div>
      </form>
    </>
  );
};

export default UserAiChatPromptSettingForm;
