import React, { useState } from "react";
import GenerateLogItem from "./GenerateLogItem";
import { UsersAi } from "../../../../interfaces/users_ai";

export type GenerateLogProps = {
  generateLog: { date: string; usersAis: UsersAi[] }[];
  othersGenerateLog: { date: string; usersAis: UsersAi[] }[];
  onClickSideTitle: (usersAi: UsersAi) => void;
};

const TextGenerateLog: React.FC<GenerateLogProps> = (props) => {
  const [selectedHistory, setSelectedHistory] = useState<string>("mine");

  const toggleHistory = (history_name: string) => {
    setSelectedHistory(history_name);
  };

  const getHistoryClassName = (history_name: string) => {
    return selectedHistory === history_name
      ? "inline-block p-4 grow text-cwaipurple-600 border-b-2 border-cwaipurple-600" // 選択されている履歴のクラス名
      : "inline-block p-4 grow text-gray-900 hover:text-cwaipurple-600 border-b"; // 選択されていない履歴のクラス名
  };

  const renderHistory = () => {
    switch (selectedHistory) {
      case "mine":
        return (
          <>
            <div className="px-3 pb-2 pt-4">
              <div className="text-sm">
                <div>
                  {props.generateLog.map((itemProps, i) => (
                    <GenerateLogItem
                      key={`my-generate-log-item-${i}`}
                      date={itemProps.date}
                      usersAis={itemProps.usersAis}
                      onClickSideTitle={props.onClickSideTitle}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
        );
      case "others":
        return (
          <>
            <div className="p-3">
              <div className="text-sm mb-6">
                <div>
                  {props.othersGenerateLog.map((itemProps, i) => (
                    <GenerateLogItem
                      key={`my-generate-log-item-${i}`}
                      date={itemProps.date}
                      usersAis={itemProps.usersAis}
                      onClickSideTitle={props.onClickSideTitle}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="px-3 pb-2 pt-4" id="styled-my-result">
              <div className="text-sm">
                <div>
                  {props.generateLog.map((itemProps, i) => (
                    <GenerateLogItem
                      key={`my-generate-log-item-${i}`}
                      date={itemProps.date}
                      usersAis={itemProps.usersAis}
                      onClickSideTitle={props.onClickSideTitle}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <>
      <div className="flex grow flex-col border-l">
        {/* 生成履歴のタブ */}
        <div className="flex flex-row">
          <ul className="flex grow text-base text-center">
            <li className="flex grow">
              <button
                className={getHistoryClassName("mine")}
                onClick={() => toggleHistory("mine")}
              >
                自分の履歴
              </button>
            </li>
            <li className="flex grow">
              <button
                className={getHistoryClassName("others")}
                onClick={() => toggleHistory("others")}
              >
                他の人の履歴
              </button>
            </li>
          </ul>
        </div>

        <div className="basis-full">{renderHistory()}</div>
      </div>
    </>
  );
};
export default TextGenerateLog;
