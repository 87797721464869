import React, { useState, useEffect, useRef } from "react";

import { User } from "../../../../interfaces/user";
import { RagFile } from "../../../../interfaces/biz/rag_file";

import FileUploadDialog from "./FileUploadDialog";
import FileDeleteDialog from "./FileDeleteDialog";
import { toast } from "react-toastify";
import Loading from "../../Loading";

import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";

type Props = {
  currentOrganizationUserRole: string;
  ragFiles: RagFile[];
  user: User;
};

const RagFilesIndex: React.FC<Props> = (props) => {
  const [data, setData] = useState([]);
  const [file, setFile] = useState<File | null>(null);
  const [deleteFile, setDeleteFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showPulldown, setShowPulldown] = useState({});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleShowDialog = (value: boolean) => {
    setShowDialog(value);
  };

  const handleShowDeleteDialog = (value: boolean) => {
    setShowDeleteDialog(value);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteButtonClick = (deleteFile) => {
    setDeleteFile(deleteFile);
    setShowPulldown(false);
    setShowDeleteDialog(true);
  };

  const handleShowPulldown = (fileName) => {
    setShowPulldown(prevState => ({
      ...prevState,
      [fileName]: !prevState[fileName]
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setShowDialog(true);
      setFile(file);

      // 同じファイルをアップロードするとonChangeイベントが効かなくなるのでvalueをクリア
      event.target.value = "";
    }
  };



  const handleLoading = (value: boolean) => {
    setLoading(value);
  };

  useEffect(() => {
    const showToast = sessionStorage.getItem("showToast");
    if (showToast) {
      toast.success(sessionStorage.getItem("toastMessage"));
      sessionStorage.removeItem("showToast");
      sessionStorage.removeItem("toastMessage");
    }
  }, []);

  return (
    <>
      {loading && (
        <div className="loading-overlay">
          <Loading flag={loading} />
        </div>
      )}
      <div className="text-sm text-left font-bold border-b py-2 px-3 mb-2 bg-gray-100 flex justify-between items-center">
        <h1>データ登録</h1>
        <div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <button
            className="rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={handleButtonClick}
          >
            <i className="fas fa-plus mr-1"></i>新規データ登録
          </button>
        </div>
      </div>

      <div>
        <div className="sm:px-8 px-3">
          <table className="mt-4 w-full border-collapse border-gray-300 text-left">
            <thead>
              <tr>
                <th className="text-sm border-t border-b py-3 px-4">
                  ファイル名
                </th>
                <th className="text-sm border-t border-b py-3 px-4">拡張子</th>
                <th className="text-sm border-t border-b py-3 px-4">
                  ファイルサイズ
                </th>
                <th className="text-sm border-t border-b py-3 px-4">
                  最終更新日
                </th>
                {props.currentOrganizationUserRole == "admin" && (
                  <th className="border-t border-b py-3 px-4"></th>
                )}
              </tr>
            </thead>
            <tbody>
              {props.ragFiles.map((ragFile) => (
                <tr key={ragFile.fileName}>
                  <td className="border-t border-b py-3 px-4">
                    <a
                      href={ragFile.downloadUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline text-blue-700 hover:text-blue-500"
                    >
                      {ragFile.fileName}
                    </a>
                  </td>
                  <td className="border-t border-b py-3 px-4">
                    <div>{ragFile.extension}</div>
                  </td>
                  <td className="border-t border-b py-3 px-4">
                    <div>{ragFile.fileSizeText}</div>
                  </td>
                  <td className="border-t border-b py-3 px-4">
                    <div>{ragFile.updatedAt}</div>
                  </td>
                  {props.currentOrganizationUserRole === "admin" && (
                    <td className="relative border-t border-b py-3 px-4">
                      <button
                        className="flex text-sm rounded-full"
                        type="button"
                        aria-expanded="false"
                        onClick={() => handleShowPulldown(ragFile.fileName)}
                      >
                        <EllipsisHorizontalIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                      {showPulldown[ragFile.fileName] &&(
                        <div
                          className="absolute w-28 top-5 right-5 my-0 transform z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow border"
                        >
                          <ul className="py-1" role="none">
                            <li>
                              <button
                                className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                                onClick={() => handleDeleteButtonClick(ragFile)}
                              >
                                削除する
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showDialog && (
        <FileUploadDialog
          open={showDialog}
          handleShowDialog={handleShowDialog}
          handleLoading={handleLoading}
          file={file}
        />
      )}

      {showDeleteDialog && deleteFile !== null &&(
        <FileDeleteDialog
          open={showDeleteDialog}
          handleShowDialog={handleShowDeleteDialog}
          handleLoading={handleLoading}
          deleteFile={deleteFile}
        />
      )}
    </>
  );
};

export default RagFilesIndex;
