import React, { useContext } from "react";
import { WhisperAi } from "@/interfaces/whisper_ai";
import { toast } from "react-toastify";

type Props = {
  whisperAi: WhisperAi;
};
const UserAiChatHeader: React.FC<Props> = (props) => {
  return (
    <>
      <div className="border-b border-gray-200 pr-5 mt-3">
        <div className="pb-4 md:px-4 flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <div className="relative flex items-start space-x-2 mb-4 sm:mb-0">
            <div className="relative w-16 min-w-fit mr-2">
              <img
                src={props.whisperAi.image.thumb.url}
                alt=""
                className="w-16 h-16 rounded-md object-cover"
              />
            </div>
            <div className="flex flex-col leading-tight">
              <div className="lg:text-xl text-lg mt-1 flex items-start">
                <span className="text-gray-700 mr-3">
                  {props.whisperAi.name}
                </span>
              </div>
              <p className="mt-1 text-xs text-gray-500">
                {props.whisperAi.intro}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAiChatHeader;
