import React, { useState } from "react";

//interfaces
import { InputField } from "@/interfaces/input_field";
import { User } from "@/interfaces/user";

//components
import InputText from "../forms/InputText";
import TextArea from "../forms/TextArea";
import InputSelect from "../forms/InputSelect";

//libraries
import { useForm } from "react-hook-form";

type Props = {
  submit: (data: any) => void;
  inputFields: InputField[];
  streaming: boolean;
  cancelStreaming: () => void;
  user?: User;
  ctaText?: string;
};
const UserAiChatPromptSettingForm: React.FC<Props> = (props) => {
  const [publish, setPublish] = useState(true);

  const {
    register,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useForm();

  const inputTag = (aiForm, idx) => {
    if (aiForm.kind == "input_text") {
      return (
        <InputText
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
          setValue={setValue}
        />
      );
    } else if (aiForm.kind == "text_area") {
      return (
        <TextArea
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
          setValue={setValue}
        />
      );
    } else if (aiForm.kind == "input_select") {
      return (
        <InputSelect
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
          setValue={setValue}
        />
      );
    }
  };

  const onSubmit = async () => {
    // 手動でバリデーションを行う
    const result = await trigger();
    if (result) {
      const data = getValues();
      if (publish) {
        data.publish_flag = "true";
      }
      props.submit(data);
    }
  };

  const submitBtn = () => {
    if (props.streaming == true) {
      return (
        <button className="w-full md:w-1/2 rounded bg-cwaipurple-600 py-2 mb-4 text-md font-semibold text-white shadow-sm hover:opacity-90">
          生成中です。30秒ほどお待ちください...
        </button>
      );
    } else {
      return (
        <button
          type="button"
          onClick={onSubmit}
          className="w-full md:w-1/2 rounded bg-cwaipurple-600 py-2 mb-4 text-md font-semibold text-white shadow-sm hover:opacity-90"
        >
          {props.ctaText ? <>{props.ctaText}</> : <>生成する</>}
        </button>
      );
    }
  };

  return (
    <>
      {props.inputFields.length > 0 ? (
        <>
          <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            <div className="sm:shadow-lg sm:rounded-lg">
              <div className="space-y-6 bg-white py-6 px-2 sm:p-6">
                <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-6 gap-3">
                  {props.inputFields.map((aiForm, idx) => {
                    return inputTag(aiForm, idx);
                  })}
                </div>
              </div>

              <div className="bg-[#f7f9f9] px-4 py-3 text-center sm:px-6">
                {submitBtn()}
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          <div className="text-center">{submitBtn()}</div>
        </>
      )}
    </>
  );
};

export default UserAiChatPromptSettingForm;
