import React, { useState } from "react";

//interface
import { User } from "@/interfaces/user";

//library
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

type Props = {
  submit: (data: any) => void;
  uploading: boolean;
  user?: User;
};
const UserAiChatPromptSettingForm: React.FC<Props> = (props) => {
  const [dirty, setDirty] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isDragging, setIsDragging] = useState(false);

  const {
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      movieFile: "",
      videoUrl: "",
    },
  });

  const onSubmit = async (data: any) => {
    if (!data.videoUrl && !data.movieFile) {
      toast.error("動画のURLかファイルを選択してください");
      return;
    }
    await props.submit(data);
    setValue("movieFile", "");
    setValue("videoUrl", "");
    setSelectedFileName("");
    setDirty(false);
  };

  const handleFileChange = (event) => {
    // 追加：ファイル選択時のイベントハンドラ
    const file = event.target.files[0];
    if (file) {
      setSelectedFileName(file.name); // 選択されたファイル名を更新
      setValue("movieFile", file); // この行を追加
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedFileName(file.name);
      setValue("movieFile", file);
    }
    setIsDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const submitBtn = () => {
    if (props.uploading == true) {
      return (
        <button
          className="w-full md:w-1/2 rounded bg-cwaipurple-600 py-2 mb-4 text-md font-semibold text-white shadow-sm hover:opacity-90"
          disabled
        >
          解析中です。動画の尺によっては数分かかります
        </button>
      );
    } else {
      return (
        <button
          type="submit"
          className="w-full md:w-1/2 rounded bg-cwaipurple-600 py-2 mb-4 text-md font-semibold text-white shadow-sm hover:opacity-90"
        >
          文字起こしする
        </button>
      );
    }
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className="sm:shadow-lg sm:rounded-lg">
          <div className="flex items-center justify-center w-full p-4">
            <label
              htmlFor="dropzone-file"
              className={`flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer ${
                isDragging
                  ? "bg-blue-600 text-white"
                  : "bg-gray-50 text-gray-600"
              }  hover:bg-gray-100`}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                {selectedFileName ? (
                  <>
                    <p className="text-sm font-medium">
                      選択されたファイル: {selectedFileName}
                    </p>
                  </>
                ) : (
                  <>
                    <svg
                      className="w-8 h-8 mb-4"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    {isDragging ? (
                      <>
                        <p className="mb-2 text-sm">
                          <span className="font-semibold">
                            ドロップしてください
                          </span>
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="mb-2 text-sm">
                          <span className="font-semibold">
                            クリックしてアップロード
                          </span>{" "}
                          もしくはファイルをドラッグ&ドロップしてください
                        </p>
                        <p className="text-xs">
                          ※MP4 MP3 ファイルのみ対応。音声ファイルで25MB以下
                        </p>
                      </>
                    )}
                  </>
                )}
              </div>
              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                onChange={handleFileChange}
              />
            </label>
          </div>

          <div className="bg-[#f7f9f9] px-4 py-3 text-center sm:px-6">
            {submitBtn()}
          </div>
        </div>
      </form>
    </>
  );
};

export default UserAiChatPromptSettingForm;
