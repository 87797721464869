import React from "react";
import DropdownMenu from "./DropdownMenu";
import { initializeSidebar, getSidebarItems, SidebarItem } from "./Items";
import IconWithTitle from "@/components/biz/partials/sidebar/IconWithTitle";

type SidebarProps = {
  mainCategories: any[];
  currentOrganizationName: string;
  currentOrganizationUserRole: string;
};

const Sidebar: React.FC<SidebarProps> = (props) => {
  initializeSidebar(props.mainCategories);
  const sidebarItems: SidebarItem[] = getSidebarItems();
  // 特定の法人にだけ公開する
  const permissionedOrganizations = ['crowdworks ai', 'デジタル総合印刷株式会社']

  return (
    <nav
      className="h-screen px-4 pt-2 pb-24 overflow-y-auto text-sm border-r"
      role="navigation"
      aria-label="Sidebar"
    >
      <div className="pt-3 space-y-3">
        {sidebarItems.map((item, index) =>
          item.title === "データ管理" && (!permissionedOrganizations.includes(props.currentOrganizationName) || props.currentOrganizationUserRole === "member") ? null : item.items && item.items.length > 0 ? (
            <React.Fragment key={index}>
              <DropdownMenu
                icon={item.icon}
                title={item.title}
                items={item.items}
              />
            </React.Fragment>
          ) : (
            <React.Fragment key={index}>
              <IconWithTitle
                icon={item.icon}
                title={item.title}
                url={item.url}
              />
            </React.Fragment>
          )
        )}
      </div>
    </nav>
  );
};

export default Sidebar;
