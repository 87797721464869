import React, { useState, useEffect, useRef } from "react";
import {
  updateUsersTextGenerate,
  updateGenerationResultPrivateFlag,
} from "../../../../../../libs/api/users/users_ai";
import { Ai } from "../../../../../../interfaces/ai";
import { User } from "../../../../../../interfaces/user";
import { BizGenerationResult } from "../../../../../../interfaces/biz/users_ai";
import ActionBtns from "./ActionBtns";
import { toast } from "react-toastify";
import { BizRetrievedReference } from "@/interfaces/biz/retrieved_reference";

type Props = {
  ai: Ai;
  user?: User;
  message: string;
  generationResult: BizGenerationResult;
  retrievedReferences: Array<BizRetrievedReference>;
  showCounter?: boolean;
  showAction?: boolean;
  streaming: boolean;
  setMessage?: (s: any) => void;
  addEditorBody?: (s: any) => void;
  openSave?: boolean;
  setOpenSave?: (s: any) => void;
  openUpgrade?: boolean;
  setOpenUpgrade?: (s: any) => void;
  formToken?: string;
};

/**
 * テキストAIメニューの生成結果表示コンポーネント
 */
const UserAiChatMessagesAi: React.FC<Props> = (props) => {
  if (props.ai == undefined || !props.generationResult) {
    return <></>;
  }

  const [isEdit, setIsEdit] = useState(false);
  const [prevMessage, setPrevMessage] = useState("");
  const [privateFlag, setPrivateFlag] = useState<boolean>(
    props.generationResult.private
  );

  const spanRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    // この行がないと props.generationResult を更新しても privateFlag が更新されない
    setPrivateFlag(props.generationResult.private);
  }, [props]);

  const messageClassName = () => {
    if (props.streaming) {
      return "px-4 py-2 w-full inline-block font-medium text-gray-900 whitespace-pre-wrap break-words streaming";
    } else {
      return "px-4 py-2 w-full inline-block font-medium text-gray-900 whitespace-pre-wrap break-words";
    }
  };

  const onClickEditContent = async () => {
    if (spanRef.current && props.formToken) {
      const content = spanRef.current.textContent;

      // contentを使って必要な処理を行う
      const res = await updateUsersTextGenerate(
        props.ai.slug,
        content,
        props.formToken
      );
      if (res.status === 200) {
        toast.success("編集内容を保存しました。");
      }
    }
  };

  const onChangePrivateFlag = async (newPrivateFlag) => {
    updateGenerationResultPrivateFlag(
      props.generationResult.id,
      newPrivateFlag
    ).then((response) => {
      setPrivateFlag(newPrivateFlag);
    });
  };

  return (
    <>
      {/* ヘッダー */}
      <div className="relative flex space-x-2">
        <div className="flex-0 flex items-end w-full">
          <div className="flex w-full">
            {/* アクションボタン(ダウンロードとか) */}
            {props.showAction && (
              <ActionBtns
                ai={props.ai}
                user={props.user}
                message={props.message}
                addEditorBody={props.addEditorBody}
                openSave={props.openSave}
                openUpgrade={props.openUpgrade}
                setOpenSave={props.setOpenSave}
                setOpenUpgrade={props.setOpenUpgrade}
                setIsEdit={setIsEdit}
                setMessage={props.setMessage}
                setPrevMessage={setPrevMessage}
                privateFlag={privateFlag}
                togglePrivateFlagCallback={(newPrivateFlag: boolean) => {
                  onChangePrivateFlag(newPrivateFlag);
                }}
                showTogglePrivateFlagButton={
                  props.generationResult.userId === props.user.id
                }
              />
            )}
          </div>
        </div>
      </div>

      <div className="chat-message mt-3">
        <div className="flex items-start">
          <div className="flex flex-col space-y-2 text-md order-2 items-start w-full">
            <div className="w-full">
              {/* 生成結果 */}
              <div className="border">
                <span
                  ref={spanRef}
                  className={messageClassName()}
                  contentEditable={isEdit}
                >
                  {props.message}
                </span>
                {!props.streaming && props.retrievedReferences.length > 0 && (
                  <div className="px-4 py-2">
                    【参照元ファイル】
                    <br />
                    {props.retrievedReferences.map((reference, index) => (
                      <span key={index}>
                        <a
                          href={reference.downloadUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="underline text-blue-700 hover:text-blue-500"
                        >
                          {reference.filename}
                        </a>
                        {index < props.retrievedReferences.length - 1 && ", "}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {isEdit && (
          <div className="flex justify-center space-x-2 mt-2">
            <button
              onClick={() => {
                setIsEdit(false);
                if (spanRef.current && props.setMessage) {
                  spanRef.current.textContent = prevMessage;
                  props.setMessage(prevMessage);
                }
              }}
              type="button"
              className="rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              保存せずに閉じる
            </button>
            <button
              type="button"
              className="rounded bg-cwaipurple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cwaipurple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cwaipurple-800"
              onClick={() => {
                onClickEditContent();
                setIsEdit(false);
                if (spanRef.current && props.setMessage) {
                  props.setMessage(spanRef.current.textContent);
                }
              }}
            >
              編集を保存する
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default UserAiChatMessagesAi;
