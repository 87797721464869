import React from "react";
import { FileText } from "lucide-react";
import { UsersAi } from "../../../../interfaces/users_ai";

// TODO: AIのフォームを受け取ってフォームに入力する
export type GenerateLogItemProps = {
  date: string;
  usersAis: UsersAi[];
  onClickSideTitle: (usersAi: UsersAi) => void;
};

const GenerateLogItem: React.FC<GenerateLogItemProps> = (props) => {
  return (
    <>
      <div className="mb-5">
        <span className="text-gray-400 text-sm rounded-full inline-block">
          {props.date}
        </span>
        <div className="grid">
          {props.usersAis.map((usersAi) => (
            <React.Fragment key={usersAi.id}>
              {/* TOOD: ボタン押下時にフォームに情報を入力できるようにする */}
              <a
                className="text-base cursor-pointer"
                onClick={() => props.onClickSideTitle(usersAi)}
              >
                <div className="flex items-center">
                  <div className="ml-1 line-clamp-1 text-gray-600 p-1 m-0.5 rounded hover:bg-gray-100">
                    {usersAi.sidebarTitle}
                  </div>
                </div>
              </a>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default GenerateLogItem;
